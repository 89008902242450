function cancelAttachFile(input_id,id_err,text){
	$(input_id).val('');
	return $(id_err).html(text);
}

/**popup thông báo sử dụng modal của bootstrap*/
function messageNotice(msg){
	$("#popup_modal").modal();
	
	$('#popup_modal').html('<div class="modal-dialog">\
								<div class="modal-content">\
									<div class="modal-header">\
										<button type="button" class="close" data-dismiss="modal">&times;</button>\
										<h2 class="modal-title"><strong>Thông báo!</strong></h2>\
									</div>\
									<div class="modal-body">\
										<div class="row"><div class="col-xs-12 text-center p-y-15">\
											<p>'+msg+'</p>\
											<button type="button" class="btn btn-default btn_cancel" data-dismiss="modal">Đóng</button>\
										</div>\
										</div>\
									</div>\
								</div>\
							</div>');
}

//Thiết lập thời gian ẩn thông báo
function hideMessage(){
	$("#message_top").fadeOut('medium');
	$("#flashMessage").fadeOut('medium');
}

//Lên đầu trang
function backToTop(){
	// hide #back-top first
	$("#back-top").hide();
	
	// fade in #back-top
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('#back-top').fadeIn();
				//fix top menu
				//$('#nav_menu').addClass('navbar-fixed-top');
			} else {
				$('#back-top').fadeOut();
				//$('#nav_menu').removeClass('navbar-fixed-top');
			}
		});

		// scroll body to 0px on click
		$('#back-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});
	});
}

//Lấy kích thước màn hình
function windowSize(){
	var width = 0, height = 0;
	if( typeof( window.innerWidth ) == 'number' ) {
	  //Non-IE
	  width = window.innerWidth;
	  height = window.innerHeight;
	} else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
	  //IE 6+ in 'standards compliant mode'
	  width = document.documentElement.clientWidth;
	  height = document.documentElement.clientHeight;
	} else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
	  //IE 4 compatible
	  width = document.body.clientWidth;
	  height = document.body.clientHeight;
	}
	return [width,height];
}
//Xem thêm nội dung
function more_description(id){
	$(".more_"+id).fadeIn(400);
	$(".less_"+id).hide();
}

function less_description(id){
	$(".less_"+id).fadeIn(400);
	$(".more_"+id).hide();
}
//disable paste to input
function disablePaste(id){
	$("#"+id).on('paste',function(e){
		return false;
	});
}
function number_format (number, decimals, dec_point, thousands_sep) {
	  // http://kevin.vanzonneveld.net
	  // + original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
	  // + improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	  // + bugfix by: Michael White (http://getsprink.com)
	  // + bugfix by: Benjamin Lupton
	  // + bugfix by: Allan Jensen (http://www.winternet.no)
	  // + revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
	  // + bugfix by: Howard Yeend
	  // + revised by: Luke Smith (http://lucassmith.name)
	  // + bugfix by: Diogo Resende
	  // + bugfix by: Rival
	  // + input by: Kheang Hok Chin (http://www.distantia.ca/)
	  // + improved by: davook
	  // + improved by: Brett Zamir (http://brett-zamir.me)
	  // + input by: Jay Klehr
	  // + improved by: Brett Zamir (http://brett-zamir.me)
	  // + input by: Amir Habibi (http://www.residence-mixte.com/)
	  // + bugfix by: Brett Zamir (http://brett-zamir.me)
	  // + improved by: Theriault
	  // + input by: Amirouche
	  // + improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	  // * example 1: number_format(1234.56);
	  // * returns 1: '1,235'
	  // * example 2: number_format(1234.56, 2, ',', ' ');
	  // * returns 2: '1 234,56'
	  // * example 3: number_format(1234.5678, 2, '.', '');
	  // * returns 3: '1234.57'
	  // * example 4: number_format(67, 2, ',', '.');
	  // * returns 4: '67,00'
	  // * example 5: number_format(1000);
	  // * returns 5: '1,000'
	  // * example 6: number_format(67.311, 2);
	  // * returns 6: '67.31'
	  // * example 7: number_format(1000.55, 1);
	  // * returns 7: '1,000.6'
	  // * example 8: number_format(67000, 5, ',', '.');
	  // * returns 8: '67.000,00000'
	  // * example 9: number_format(0.9, 0);
	  // * returns 9: '1'
	  // * example 10: number_format('1.20', 2);
	  // * returns 10: '1.20'
	  // * example 11: number_format('1.20', 4);
	  // * returns 11: '1.2000'
	  // * example 12: number_format('1.2000', 3);
	  // * returns 12: '1.200'
	  // * example 13: number_format('1 000,50', 2, '.', ' ');
	  // * returns 13: '100 050.00'
	  // Strip all characters but numerical ones.
	  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
	  var n = !isFinite(+number) ? 0 : +number,
	    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
	    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
	    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
	    s = '',
	    toFixedFix = function (n, prec) {
	      var k = Math.pow(10, prec);
	      return '' + Math.round(n * k) / k;
	    };
	  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
	  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	  if (s[0].length > 3) {
	    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	  }
	  if ((s[1] || '').length < prec) {
	    s[1] = s[1] || '';
	    s[1] += new Array(prec - s[1].length + 1).join('0');
	  }
	  return s.join(dec);
}
//show more language paire
/*function chkShowMore(classhide,id_more){
	$("."+classhide).removeClass('hide');
	$("#"+id_more).addClass('hide');;
}*/
/** giới hạn ký tự - Ngô Văn Nam*/
function charLimit(character, limit,span_id) {
	  if (character.value.length > limit) {/*Nếu số ký tự quá giới hạn*/
		  character.value = character.value.substring(0, limit);
	   		$('#'+span_id).css('color','red');/*Set color red*/
	  }else{
		  $('#'+span_id).css('color','#777');  /*Set color #777*/
	  }
	   $('#'+span_id).html((limit-character.value.length)+'/'+limit); /* Gán giá trị mới cho #div_id*/
}
/**hàm chỉ cho phép nhập chữ số*/
function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
function selectChangeCountry(id){
	if(id!=1){
		$("#s_province").attr("disabled","disabled");
		$("#s_province").val("");
	}else{
		$("#s_province").removeAttr('disabled');
	}
}
$(document).ready(function(){
	w_window = windowSize();
	if(w_window[0]>768){
		$('.auto_dropdown').mouseover(function(){
			$(this).addClass('open');
		});
		$('.auto_dropdown').mouseout(function(){
			$(this).removeClass('open');
		});
	}else{
		//$('.auto_dropdown').prepend('<a href="javascript:;" class="dropdown-toggle pull-right hidden-lg" data-toggle="dropdown" aria-expanded="true"><span class="caret"></span></a>')
	}
	/*lên đầu trang*/
	backToTop();
	//Hiệu ứng hover chuột vào ảnh
	$(".thumb a img").mouseover(function(){
		$(this).animate({opacity: 0.7},200);
	});
	$(".thumb a img").mouseout(function(){
		$(this).animate({opacity: 1},200);
	});
	
	//Ẩn thông báo
	var t=setTimeout('hideMessage()',5000);
	//Thêm .img-responsive cho tag img
	$(".adv_block img").attr('class','img-responsive');
	//Thêm ALT cho tag img
	$(".adv_block img").attr('alt','Quảng cáo');
	
	/**realtime pusher */
	Pusher.logToConsole = false;
	var pusher = new Pusher('9fcb8e553e06f6000f55', {
		encrypted: true
	});
	var channel = pusher.subscribe('CheckOrder');
	/** chanel trùng voi chanel trong hàm send()*/
	channel.bind('notice', function (data) {
		/**hiển thị số tin nhắn mới*/
		if(!$("#number_message_"+data.member_id).hasClass('num_notice')){
			$("#number_message_"+data.member_id).addClass('num_notice');
		}
		$("#number_message_"+data.member_id).html(data.num_new_msg);
	});
	/*Hoán đổi ngôn ngữ*/
	$("#swap_lang").click(function(){
		var language_origin = $("#language_origin").val();
		var language_target = $("#language_target").val();
		$("#language_origin").val(language_target);
		$("#language_target").val(language_origin);
	});
});

