(function($){

	jQuery.fn.jConfirmAction = function (options) {
		// Some jConfirmAction options (limited to customize language) :
		// question : a text for your question.
		// yesAnswer : a text for Yes answer.
		// cancelAnswer : a text for Cancel/No answer.
		var theOptions = jQuery.extend ({
			title: "Notice",
			question: "Are You Sure ?",
			yesAnswer: "Yes",
			cancelAnswer: "Cancel"
		}, options);
		
		return this.each (function () {
			$(this).bind('click', function(e) {
				$('#popup_modal').modal('show');
				e.preventDefault();
				thisHref	= $(this).attr('href');
				if($(this).next('.question').length <= 0)
					$('#popup_modal').html('<div class="modal-dialog modal-sm">\
						<div class="modal-content">\
							<div class="modal-header">\
								<button type="button" class="close" data-dismiss="modal">&times;</button>\
								<h4 class="modal-title"><strong>'+theOptions.title+'</strong></h4>\
							</div>\
							<div class="modal-body">\
								<h3>'+theOptions.question+'</h3>\
								<div id="popupnotice" class="row">\
									<div class="col-xs-12">\
										<button type="button" id="submitOK" data-dismiss="modal" class="btn btn-default btn_update pull-right m-x-15 yes">'+theOptions.yesAnswer+'</button>\
										<button type="button" data-dismiss="modal" class="btn btn-default btn_cancel pull-left m-x-15 cancel">'+theOptions.cancelAnswer+'</button>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>');
				$(this).next('.question').animate({opacity: 1}, 300);
				
				$('.yes').bind('click', function(){
					window.location = thisHref;
				});
		
				$('.cancel').bind('click', function(){
					$(this).parents('.question').fadeOut(300, function() {
						$(this).remove();
					});
				});
				
			});
			
		});
	}
	
})(jQuery);